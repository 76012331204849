<template>
    <div>
        <component :is="componentMapping[country]" :brandConfig="brandConfig" :country="country" :storeId="storeId"
            :storeName="storeName" />
    </div>
</template>

<script>
export default {
    components: {
        MY: () => import("./MY/Registration.vue"),
        TH: () => import("./TH/Registration.vue"),
        PH: () => import("./PH/Registration.vue"),
    },
    props: {
        brandConfig: {
            type: Object,
            default: () => {
                return {};
            },
        },
        country: {
            type: String,
            default: "",
        },
        storeId: {
            type: String,
            default: "",
        },
        storeName: {
            type: String,
            default: "",
        },
    },
    created() {
    },
    data() {
        return {
            componentMapping: {
                MY: "MY",
                TH: "TH",
                PH: "PH"
            },
        };
    },
    watch: {},
    methods: {}
}
</script>

<style scoped></style>
